import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import EngagementBlock from '../components/engagement/engagement-block'
import DefaultLayout from '../layouts/default'
import { Calculator as RepaymentCalculator } from '../components/repayment-calculator/calculator';
import StampDutyCalculator from '../components/stamp-duty-calculator/stamp-duty-calculator';
import styled, { css } from 'styled-components';
import logo from '../images/logo.svg'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'

const TabWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 500px;
    justify-content: space-between;
    margin: auto;
    margin-top: 30px;
`;

const Tab = styled.div`
    width: 250px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #BE996A;
    transition: 0.5s;
    color: #21232F;

    ${({ isSelected }) => isSelected && css`
        color: white;
        background-color: #BE996A;
    `}

    :hover {
        color: white;
        background-color: #BE996A;
    }
`;

const Description = styled.p`
  color: #21232F;
  opacity: 0.6;
  width: 100%;
  margin: 40px 0px;
  font-size: 18px;
`

const ContentWrapper = styled.div`
  height: 300px;
  width: 1200px;
  margin: auto;
`

const Wrapper = styled.div`
  background: #F2EBE1;
  min-width: 1300px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 74px;
  color: #1F3F68;
`
const HeaderDescription = styled.p`
  color: #1F3F68;
  opacity: 0.6;
  width: 400px;
`

const Calculators = ({ path }) => {
    const [tabIndex, setTabIndex] = useState(0);

    return <DefaultLayout path={path}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Calculators - CY Finance</title>
        </Helmet>
        <Header index={3} />
        <Wrapper className='px-10'>
            <ContentWrapper className='flex justify-between items-center'>
            <div className='flex flex-col gap-2'>
                <Title>Calculators</Title>
                <HeaderDescription>Simple tools to help you make an informed decision about your home loan</HeaderDescription>
            </div>
            <img src={logo} alt='logo' width={200} height={200} className='mr-60' />
            </ContentWrapper>
        </Wrapper>
        <TabWrapper>
            <Tab className="shadow border" isSelected={tabIndex === 0} onClick={() => {setTabIndex(0)}}>Repayment Calculator</Tab>
            <Tab className="shadow border" isSelected={tabIndex === 1} onClick={() => {setTabIndex(1)}}>Stamp Duty Calculator</Tab>
        </TabWrapper>
        <div className='px-5 text-center'>
            {
                tabIndex === 0 ? 
                <>
                    <Description>Work out how much your home loan repayments might be</Description>
                    <RepaymentCalculator />
                </>
                :
                <>
                    <Description>Work out how much your stamp duty could be when looking to purchase a property</Description>
                    <StampDutyCalculator />
                </>
            }
        </div>
        <EngagementBlock />
        <Footer index={3} />
    </DefaultLayout>
}

export default Calculators
