import React from 'react';
import styled from 'styled-components';

const localeOptions = {
	style:'currency',
	currency:'AUD',
	minimumFractionDigits: 0,
    maximumFractionDigits: 0
}

const FigureBlock = styled.div`
	color: #21232F;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	span{
		font-size: 30px;
	}
`;

const Wrapper = styled.div`
	padding: 0 20px;
	gap: 30px;
`;

const Separator = styled.span`
	color: #BE996A;
	font-size: 1.25rem/* 20px */;
    line-height: 1.75rem/* 28px */;
`;

export const HeaderFigures = props => {
	return(
		<Wrapper className="flex flex-row items-start justify-center mb-10">
			<FigureBlock>
				<span >
					{parseInt(props.amountToBorrow).toLocaleString('en-GB', localeOptions)}
				</span>
				<div >Amount To Borrow</div>
			</FigureBlock>
			<Separator>|</Separator>
			<FigureBlock >
				<span >
					{parseInt(props.monthlyPayment).toLocaleString('en-GB', localeOptions)}
				</span>
				<div >Monthly Payment</div>
			</FigureBlock>
			{
				props.interestOnlyMonthlyPayment > 0 && 
				<>
					<Separator>|</Separator>
					<FigureBlock>
						<span>
							{parseInt(props.interestOnlyMonthlyPayment).toLocaleString('en-GB', localeOptions)}
						</span>
						<div>Monthly Payment</div>
						<div className="text-gray-400 text-xs">(During Interest Only Period)</div>
					</FigureBlock>
				</>
			}
			<Separator>|</Separator>
			<FigureBlock >
				<span >
					{parseInt(props.totalRepaid).toLocaleString('en-GB', localeOptions)}
				</span>
				<div >Total Repaid</div>
			</FigureBlock>
			<Separator>|</Separator>
			<FigureBlock >
				<span >
					{parseInt(props.totalInterestPaid).toLocaleString('en-GB', localeOptions)}
				</span>
				<div >Total Interest Paid</div>
			</FigureBlock>
			{/* <Separator>|</Separator>
			<FigureBlock>
				<span >
					{(props.depositAmount / props.purchasingHousePrice * 100).toFixed(1)}%
				</span>
				<div >Deposit</div>
			</FigureBlock> */}
			{/* <Separator>|</Separator>
			<FigureBlock >
				<span >
					{(props.amountToBorrow / props.purchasingHousePrice * 100).toFixed(1)}%
				</span>
				<div >Loan To Value</div>
			</FigureBlock> */}
		</Wrapper>
	)
}