import React from 'react';
import styled from 'styled-components';
import EditableText from './editable-text';

const localeOptions = {
	style: 'currency',
	currency: 'AUD',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0
}

const ControlBlock = styled.div`
	width: 180px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;

	span{
		font-size: 25px;
	}
`;

const Wrapper = styled.div`
	margin: 30px auto;
	width: 700px;
`;

// excess height to improve interactive area / accessibility
const height = "25px";
const thumbHeight = 25;
const trackHeight = "10px";

// colours
const upperColor = "#f2ebe2";
const lowerColor = "#BE996A";
const thumbColor = "#BE996A";
const thumbHoverColor = "#BE996A";
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${lowerColor}, ${lowerColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

// Webkit cannot style progress so we fake it with a long shadow on the thumb element
const makeLongShadow = (color, size) => {
  let i = 5;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < 706; i++) {
    shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
  }

  return shadow;
};

const Range = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  max-width: 700px;
  width: 100%;
  margin: 0;
  height: ${height};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    background: ${lowerBackground};
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbHeight}px;
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: ${makeLongShadow(upperColor, "-7px")};
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${lowerBackground};
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-moz-range-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-ms-thumb {
      background-color: ${thumbHoverColor};
    }
  }
`;

const EditableTextStyled = styled(EditableText)`
	cursor: pointer;
	display: flex;
	align-items: center;
	svg {
		margin-left: 5px;
	}
`;

export const CalculatorControls = props => {

	// const handleSingleTapPrice = (text) => {
	// 	props.setPurchasingHousePrice(text);
	// }

	// const handleSingleTapDeposit = (text) => {
	// 	props.setDepositAmount(text);
	// }

	const handleSingleTapLoanAmount = (text) => {
		props.setLoanAmount(text);
	}

	const handleSingleTapTerm = (text) => {
		props.setMortgageTerm(text);
	}
	
	const handleSingleTapRate = (text) => {
		props.setInterestRate(text);
	}

	return (
		<Wrapper className='flex justify-between'>
			<ControlBlock >
				<EditableTextStyled
					doubleClick={false}
					handleEnter={handleSingleTapLoanAmount}
					handleBlur={handleSingleTapLoanAmount}
					defaultValue={props.loanAmount}
				>
					<span >{parseInt(props.loanAmount).toLocaleString('en-GB', localeOptions)}</span>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
					</svg>
				</EditableTextStyled>
				<Range
					type="range"

					id="loanAmount"
					min="10000"
					max="5000000"
					step="10000"
					value={props.loanAmount}
					onChange={(event) =>
						props.setLoanAmount(event.target.value)
					} />
				<label >Loan Amount</label>
			</ControlBlock>
			{/* <ControlBlock >
				<EditableTextStyled
					doubleClick={false}
					handleEnter={handleSingleTapPrice}
					handleBlur={handleSingleTapPrice}
					defaultValue={props.purchasingHousePrice}
				>
					<span>{parseInt(props.purchasingHousePrice).toLocaleString('en-GB', localeOptions)}</span>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
					</svg>
				</EditableTextStyled>
				<Range
					type="range"
					id="purchasingHousePrice"
					min="50000"
					max="5000000"
					step="1000"
					value={props.purchasingHousePrice}
					onChange={(event) =>
						props.setPurchasingHousePrice(event.target.value)
					} />
				<label >Purchasing House Price</label>
			</ControlBlock> */}
			{/* <ControlBlock >
				<EditableTextStyled
					doubleClick={false}
					handleEnter={handleSingleTapDeposit}
					handleBlur={handleSingleTapDeposit}
					defaultValue={props.depositAmount}
				>
					<span >{parseInt(props.depositAmount).toLocaleString('en-GB', localeOptions)}</span>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
					</svg>
				</EditableTextStyled>
				<Range
					type="range"

					id="depositAmount"
					min="1000"
					max="1000000"
					step="1000"
					value={props.depositAmount}
					onChange={(event) =>
						props.setDepositAmount(event.target.value)
					} />
				<label >Deposit</label>
			</ControlBlock> */}
			<ControlBlock >
				<EditableTextStyled
					doubleClick={false}
					handleEnter={handleSingleTapTerm}
					handleBlur={handleSingleTapTerm}
					defaultValue={props.mortgageTerm}
				>
					<span >{props.mortgageTerm} Years</span>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
					</svg>
				</EditableTextStyled>
				<Range
					type="range"

					id="mortgageTerm"
					min="5"
					max="35"
					step="1"
					value={props.mortgageTerm}
					onChange={(event) =>
						props.setMortgageTerm(event.target.value)
					} />
				<label >Mortgage Term</label>
			</ControlBlock>
			<ControlBlock >
				<EditableTextStyled
					doubleClick={false}
					handleEnter={handleSingleTapRate}
					handleBlur={handleSingleTapRate}
					defaultValue={props.interestRate}
				>
					<span >{props.interestRate}%</span>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
					</svg>
				</EditableTextStyled>
				<Range
					type="range"

					id="interestRate"
					min="0.1"
					max="8"
					step="0.1"
					value={props.interestRate}
					onChange={(event) =>
						props.setInterestRate(event.target.value)
					} />
				<label >Interest Rate</label>
			</ControlBlock>
		</Wrapper>
	);
}