import React, {useState} from 'react';
import {HeaderFigures} from './header-figures';
import {CalculatorControls} from './calculator-controls';
import {PaymentGraph} from './payment-graph';
import {PaymentTable} from './payment-table';
import { handleMortgageDataChange } from './utils';
import './calculator.css';
import styled from 'styled-components';

const SelectStyled = styled.select`
	background: transparent;
    border-radius: 5px;
    border: solid 1px;
    border-color: #BE996A;
	padding: 10px 10px;
`;

export const Calculator = () => {
	// const [depositAmount, setDepositAmount] = useState(100000);
	const [loanAmount, setLoanAmount] = useState(500000);
	// const [purchasingHousePrice, setPurchasingHousePrice] = useState(500000);
	const [mortgageTerm, setMortgageTerm] = useState(30);
	const [interestRate, setInterestRate] = useState(1.8);
	const [interestOnlyTerm, setInterestOnlyTerm] = useState(0);

	//Set initial values for the whole mortgage term
	const amountToBorrow = loanAmount;//purchasingHousePrice - depositAmount;
	const monthlyPayment = ((interestRate / 100 / 12) * amountToBorrow) / (1 - (Math.pow((1 + (interestRate / 100 / 12)),((0 - (mortgageTerm - interestOnlyTerm)) * 12))));
	const totalRepaid = monthlyPayment * 12 * mortgageTerm;
	const totalInterestPaid = totalRepaid - amountToBorrow;
	const interestOnlyMonthlyPayment = interestOnlyTerm > 0 ? amountToBorrow * interestRate / 100 / 12 : 0;

	const yearlyPayments = handleMortgageDataChange(amountToBorrow, mortgageTerm, interestRate, monthlyPayment, interestOnlyTerm);

	return(
		<div className="px-10 text-gray-700 text-xl">
			<div className="mb-12">
				<label for="interest-only-term">Repayment Type: </label>
				<SelectStyled name="interest-only-term" id="interest-only-term" onChange={(e) => {setInterestOnlyTerm(parseInt(e.target.value))}}>
					<option value="0">Principle and interest</option>
					<option value="1">Interest only 1 year</option>
					<option value="2">Interest only 2 year</option>
					<option value="3">Interest only 3 year</option>
					<option value="4">Interest only 4 year</option>
					<option value="5">Interest only 5 year</option>
				</SelectStyled>
			</div>
			
			<HeaderFigures 
				amountToBorrow={amountToBorrow}
				monthlyPayment={monthlyPayment}
				totalRepaid={totalRepaid}
				totalInterestPaid={totalInterestPaid}
				// depositAmount={depositAmount}
				// purchasingHousePrice={purchasingHousePrice}
				interestOnlyMonthlyPayment={interestOnlyMonthlyPayment}
			/>
            <hr className=" w-1/2 m-auto"/>
			<CalculatorControls 
				// depositAmount={depositAmount}
				// setDepositAmount={setDepositAmount}
				// purchasingHousePrice={purchasingHousePrice}
				// setPurchasingHousePrice={setPurchasingHousePrice}
				loanAmount={loanAmount}
				setLoanAmount={setLoanAmount}
				mortgageTerm={mortgageTerm}
				setMortgageTerm={setMortgageTerm}
				interestRate={interestRate}
				setInterestRate={setInterestRate}
			/>
			<PaymentGraph
				mortgageTerm={mortgageTerm}
				yearlyPayments={yearlyPayments}
			/>
            <hr className=" w-1/2 m-auto my-10"/>
			<PaymentTable
				amountToBorrow={amountToBorrow}
				yearlyPayments={yearlyPayments}
			/>
		</div>
	);

}