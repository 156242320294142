import React from 'react'
import Helmet from "react-helmet"
import styled from 'styled-components'
// import './stamp-duty-calculator.css'

const Wrapper = styled.div`
    width: 820px;
    margin: auto;
    text-align: left;

    .grey-theme h3 {
        background: transparent !important;
        color: #21232F !important;
        border-bottom: #21232F !important;
    }

    .grey-theme .colored-block {
        border: 1px solid #21232F !important;
    }

    .grey-theme .control-group span {
        background: transparent !important;
        color: #21232F;
    }

    .grey-theme, .grey-theme input, .grey-theme select {
        color: #21232F !important;
    }

    .grey-theme .state-module button {
        cursor: pointer !important;
        background: #BE996A !important;
        border: 1px solid #21232F !important;
        transition: 0.2s !important;
        color: white !important;
    }
    
    .grey-theme input.stamp-calculate {
        cursor: pointer !important;
        background: #F3ECE3 !important;
        border: 1px solid #21232F !important;
        transition: 0.2s !important;
        color: #21232F !important;
    }

    .grey-theme input.stamp-calculate:hover {
        background: #F3ECE3 !important;
        border: 1px solid #21232F !important;
        opacity: 0.8 !important;
    }

    .grey-theme .control-group {
        border: 1px solid #21232F !important;
        border-radius: 2px !important;
    }

    .grey-theme .filled-circle {
        background: transparent !important;
        color: #21232F;
    }

    .grey-theme .total-result {
        border-top: 1px solid #21232F !important;
    }
`

const StampDutyCalculator = () => {
    return <Wrapper>
        <div id="stamp-duty-calculator" class="grey-theme hidden">
            <div id="stamp-duty-anchors">
                {/* <p>
                    More information: <a href="https://stampdutycalc.com.au/vic/">stampdutycalc.com.au/vic/</a>
                </p> */}
            </div>
        </div>
        <Helmet>
            <script id="stamp-src" type="text/javascript" data-state="VIC" src="//calculatorsonline.com.au/external/!main/stamp_duty.min.js"></script>
        </Helmet>
    </Wrapper>
}

export default StampDutyCalculator