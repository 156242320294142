import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    height: 500px;
    background: #F2EBE1;
`
const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 74px;
  color: #1F3F68;
`
const Description = styled.p`
  color: #1F3F68;
  opacity: 0.6;
  width: ${({ width }) => width ?? '450px'};
`

const ContactUsHeroButton = styled.a`
  background: #BE996A;
  box-shadow: 5px 10px 20px rgba(53, 110, 173, 0.2);
  border-radius: 10px;
  height: 56px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 40px;
`

const EngagementBlock = () => {
  return <Wrapper className='flex gap-40 justify-center items-center px-20'>
    <div className='flex flex-col gap-4'>
      <Title>Interested to know more?</Title>
      <Description>Our brokers are all highly qualified individuals and are approved Credit Advisers with the Mortgage and Finance Association of Australia</Description>
    </div>
    <ContactUsHeroButton href="/about">
      {'Let\'s Talk'}
    </ContactUsHeroButton>
  </Wrapper>
}

export default EngagementBlock
